import React from "react";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center" style={{ width: 300, margin: '0 auto' }}>
        {new Date().getFullYear()}
        {' © Powered by '}{' '}
        <Link color="inherit" href="https://slidespiel.com/">
          SlideSpiel.com
        </Link>
      </Typography>
      <br />
    </>
  );
}

export default Copyright;
