import React from 'react';
import draftToHtml from 'draftjs-to-html';
import Typography from '@material-ui/core/Typography';
import { inject } from "mobx-react";

const PrivacyPolicy = ({ portal, noMargin = false }) => {
  console.log(portal);
  return (
    <>
      {!noMargin &&
        <>
          <br />
          <br />
        </>
      }
      <Typography variant="h4" component="h1">Privacy Policy</Typography>
      {portal.privacyPolicy && <Typography component="div" dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(portal.privacyPolicy)) }} />}
    </>
  )
}

export default inject(({ store }, props) => ({
  portal: store.portal,
}))(PrivacyPolicy);