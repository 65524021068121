const config = {
  CMEfilter: false,
  mainColor: "#002C5C",
  primaryContrastText: "#fff",
  secondaryColor: "#2FA043",
  secondaryContrastText: "#fff",
  searchPlaceholder: "What do you want to learn?",
  loginText: "",
  requireAuth: true,
  showOnDemand: true,
  showBuy: false,
  showSignUp: true,
  showSponsors: true,
  showSponsorsPage: true,
  sponsorsPageCards: true,
  sponsorsCardsHorizontal: false,
  showEvents: false,
  eventsDebugMode: false,
  showProducts: false,
  showSchedule: false,
  trackPageScrollOffset: -80,
  showExhibitHall: true,
  showChat: true,
  enablePlaybackTracking: false,
  showPrivacyPolicy: true,
  // externalPrivacyPolicyUrl: "//www.ya.ru",
  // acceptPrivacyPolicyText: "Accept <Link>",
  emailHelperText: "",
  showSessionEvaluation: false,
  showRecordingEvaluation: false,
  showAskSpeaker: false,
  supportEmail: "education@iaapa.org",
  portals: [
    { 
      year: 2020,
      logo: 'logo.png',
      signInRedirect: "/event/iaapa-2021-04-07",
      trackImages: false,
      home: {
        minHeight: 500,
        height: '30vh',
        paperMarginRight: '-16vw',
        paperMaxWidth: '60vw',
        paperColor: 'rgba(0,0,0,.3)',
        backgroundUrl: 'background.png',
        backgroundPosition: { x: 50, y: 40 },
        color: "#fff",
        buttonColor: "secondary",
        getLink: "/event/iaapa-2021-04-07",
        //fontFamily: "Fira Sans",
        //fontWeight: 'bold',
      },
    },
  ],
  main: {
    logoMarginTop: 5,
    logoPadding: 0,
    logoHeight: 80,
    logoHeightXs: 50,
    progressBarTop: 89,
    progressBarTopXs: 55,
    childrenMarginTop: 89,
    childrenMarginTopXs: 50,
    searchColor: "primary",
    barColor: "default",
    barTextColor: "primary",
    barButtonColor: "secondary"
  },
  footer: {
    facebookUrl: "https://www.facebook.com/IAAPAHQ",
    twitterUrl: "https://twitter.com/IAAPAHQ",
    instagramUrl: "https://instagram.com/iaapahq",
    linkedInUrl: "https://www.linkedin.com/company/iaapa",
  },
  eventHome: {
    titleColor: "primary",
    subtitleColor: "secondary",
    liveColor: "secondary",
    registerColor: "primary",
    exhibitHallColor: "secondary",
    networkingRoomColor: "primary",
  }
};

export default config;
