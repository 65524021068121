import React from 'react';
import draftToHtml from 'draftjs-to-html'
import Typography from '@material-ui/core/Typography';

const EventCustomPage = ({ data }) => (
  <>
    <br />
    <br />
    {/* <Typography variant="h4" component="h1" gutterBottom>{data.name}</Typography> */}
    {data.text && <Typography component="div" dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(data.text)) }} />}
    <br />
    <br />
  </>
)

export default EventCustomPage;