import React, { Fragment } from 'react';
import uiConfig from '../uiConfig';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
// import Link from '@material-ui/core/Link';
import { useFeedback } from './feedback/Service';
import PrivacyPolicy from './pages/PrivacyPolicy';

const Label = () => {
  const feedback = useFeedback();
  const { acceptPrivacyPolicyText } = uiConfig;
  const sections = acceptPrivacyPolicyText.split("<Link>");
  const handleLinkClick = e => {
    e.preventDefault();
    feedback.alert({ component: (<PrivacyPolicy noMargin />), fullWidth: true, confirmButtomText: "Close", maxWidth: "md", scroll: "body", clickAwayClose: true });
  }
  return (
    <>
      {sections.map((s, i) => (
        <Fragment key={i}>
          {s}
          {i < sections.length - 1 &&
            (uiConfig.externalPrivacyPolicyUrl ?
              <Link href={uiConfig.externalPrivacyPolicyUrl} rel="noreferrer noopener" target="_blank">Privacy Policy</Link> :
              <Link href="#privacy" onClick={handleLinkClick}>Privacy Policy</Link>
            )
          }
        </Fragment>
      ))}
    </>
  );
}

const PrivacyPolicyCheckbox = ({ checked, handleChange }) => {

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={({ target }) => handleChange(target.checked)}
            color="primary"
          />
        }
        label={<Label />}
      />
    </>
  );
}

export default PrivacyPolicyCheckbox;