import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';

class PreviewDialog extends PureComponent {
  constructor(...args) {
    super(...args);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { history, location } = this.props,
          pathParts = location.pathname.split("/");
    // TODO: come up with better solution
    history.replace(pathParts.slice(0, -2).join("/"));
  }

  render() {
    const alias = this.props.match.params.recordId,
          src = `https://slidespiel.com/view/${alias}`;
    return (
      <Dialog open={true} onClose={this.handleClose} maxWidth="md" fullWidth>
        <iframe frameBorder="0" width="100%" height="400" src={src} title={`Preview recording ${src}`} allowFullScreen />
      </Dialog>
    );
  }
}

PreviewDialog.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
};

export default withRouter(PreviewDialog);
