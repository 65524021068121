import React, { useState, useEffect } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { inject } from "mobx-react";
import { CometChatUnified, CometChatMessageScreen } from "../../lib/CometChat";
import { createChatUser, chatLogin, initChat, CHAT_MODES } from './controller';
import CircularProgress from '@material-ui/core/CircularProgress';

const Chat = ({ user, exhibitorChatUid, type = CHAT_MODES.LOCAL, maxHeight = 'calc(100vh - 200px)' }) => {
  const [inited, setInited] = useState(false);
  const [exhibitor, setExhibitor] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    initChat().then(() => setInited(true)).catch();
  }, []);

  useEffect(() => {
    if (!inited) return;
    if (!user) return;

    const init = async () => {
      let { id: uid, firstName, lastName } = user;
      // uid = "c6156457-48a2-4950-8441-1718bfa99bce";
      // firstName = "Andrea";
      // lastName = "Simpson";
      try {
        await chatLogin(uid);
        setLoggedIn(true);
      } catch (e) {
        const name = `${firstName} ${lastName}`.trim();
        try {
          await createChatUser(uid, name);
          await chatLogin(uid);
          setLoggedIn(true);
        } catch (error) {
          console.error("[CHAT]", "LOGIN FAILED", error);
        }
      }
      if (type === CHAT_MODES.LOCAL)
        CometChat.getUser(exhibitorChatUid).then(exhibitor => setExhibitor(exhibitor)).catch(error => console.error("[CHAT]", "get exhibitor failed", error));
    }
    init();
  }, [inited, user, exhibitorChatUid, type]);

  if (!loggedIn) return <><br /><CircularProgress /></>;
  if (!exhibitor && type === CHAT_MODES.LOCAL) return null;

  return (
    <div className="use-bootstrap">
      {type === CHAT_MODES.LOCAL ?
        <CometChatMessageScreen item={exhibitor} type="user" maxHeight={maxHeight} /> :
        <CometChatUnified maxHeight={maxHeight} />
      }
    </div>
  );
};

export default inject(({ store }) => ({
  user: store.currentUser,
}))(Chat);