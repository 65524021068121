import React, { useState } from "react";
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/Delete';

import api from "../../api";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useFeedback } from "../feedback/Service";
import { inject } from "mobx-react";

const Members = ({ store, isFetching }) => {
  const [members, setMembers] = useState([]);
  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");
  const feedback = useFeedback();

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length > 2)
      fetch(value);
  }

  const handleAddChange = (e) => setEmail(e.target.value);

  const handleSubmit = () => {
    if (!email.trim() || !email.includes('@'))
      return;
    store.setIsFetching(true);
    api.addMember(email).then(x => {
      console.log(x);
      setEmail('');
      store.setIsFetching(false);
      feedback.snackbar({ text: "Member has been successfully added.", type: "success" });
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.responseJSON?.message || "Error adding member", type: "error" });
    });
  }

  const fetch = searchString => {
    store.setIsFetching(true);
    api.getMembers(searchString).then(({ body: data }) => {
      console.log(data);
      setMembers(data);
      store.setIsFetching(false);
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }

  const handleDelete = email => async e => {
    if (!await feedback.confirm({ title: "Remove member?", text: `Are you sure you want to delete ${email} from members?` }))
      return;
    api.deleteMember(email).then(x => {
      fetch(search);
      feedback.snackbar({ text: 'Member has been successfully deleted.', type: "success" });
    }).catch(x => {
      feedback.snackbar({ text: x.responseJSON?.message || "Error deleting member", type: "error" });
    });
  }

  return (
    <>
      <br />
      <TextField
        type="text"
        value={email}
        onChange={handleAddChange}
        name="add"
        placeholder="Enter email"
        margin="normal"
        size="small"
        onKeyPress={handleKeyPress}
        helperText="Enter member’s email to add them to the member list and to grant special price."
        variant="outlined"
      />
      <br />
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isFetching}>Submit</Button>
      <br />
      <br />
      <TextField
        fullWidth
        type="search"
        value={search}
        onChange={handleChange}
        name="search"
        placeholder="Search members by email..."
        margin="dense"
        size="small"
        variant="outlined"
      />
      <List
        component="nav"
        aria-labelledby="certificates-list-subheader"
      >
        {members.map((member, i) => (
          <ListItem key={i}>
            <ListItemText primary={member} />
            <IconButton onClick={handleDelete(member)}>
              <RemoveIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

Members.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
}

export default inject(({ store }, props) => ({
  isFetching: store.isFetching, store
}))(Members);
