export const portals = [
  {
    year: 2020,
    home: {
      mainText: "",
      // secondaryText: "",
      list: [
        // "24-hours access to the educational content",
        // "Online CE courses",
        // "Available on mobile devices"
      ],
      ctaText: "Sign Up",
      ctaTextLoggedIn: "Go To The Event",
    }
  },
];

export const loungesSchedule = [
]

export const prizeSchedule = [
]

// export const signUpFormData = {
//   size: 'sm', // or md, fullscreen
//   fields: [
//     { id: "phone", label: "Phone Number", type: "phone", mandatory: true, defaultValue: "", xs: 12, sm: 6, md: 6},
//     { id: "accId", label: "Account ID", type: "text", maxLength: 4, mandatory: true, regex: "^\\d*$", defaultValue: "", xs: 12, sm: 6, md: 6, autoComplete: undefined},
//     { id: "allowShare", label: "Share contact data", type: "radio", mandatory: true, options: [{ value: "1", label: "Yes" }, { value: "", label: "No" }], defaultValue: null, row: true, xs: 12, sm: 6, md: 6 },
//     { id: "country", label: "Country", type: "select", mandatory: true, options: [{ value: "1", label: "US" }, { value: "0", label: "Other" }], defaultValue: "1", xs: 12, sm: 6, md: 6 },
//   ]
// }

export const tiers = [
  {
    id: 0,
    title: 'Session',
    price: '60',
    memberPrice: '60',
    description: ['Individual session'],
    buttonText: 'Select',
    buttonVariant: 'outlined',
    summary: "Individual Session"
  },
  // {
  //   id: 1,
  //   title: 'Track',
  //   subheader: 'Most popular',
  //   price: '250',
  //   memberPrice: '195',
  //   description: [
  //     'Four sessions',
  //     'Keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'contained',
  //   summary: "Track (4 sessions)"
  // },
  // {
  //   id: 2,
  //   title: 'Conference',
  //   price: '450',
  //   memberPrice: '395',
  //   description: [
  //     'All sessions and keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'outlined',
  //   summary: "Entire Conference"
  // },
];

export const sponsors = {
  title: [
    { image: "Title/Disney_4x3.jpg", url: 'dpep.disney.com', email: '', name: '', description: 'Since Walt Disney ushered in a new era of family entertainment with the opening of Disneyland in Anaheim, California, on July 17, 1955, its parks and resorts business has grown to include 12 theme parks and over 50 resorts in the United States, Europe and Asia.  In addition, Disney Parks, Experiences and Products now includes a top-rated cruise line, a guided family vacation business and retail, gaming and publishing division.' },
    { image: "Title/Gateway_4x3.jpg", url: 'www.gatewayticketing.com', email: 'dwalp@gatewayticketing.com', name: 'Donna Walp', description: 'Gateway Ticketing Systems® has provided our Galaxy® ticketing and guest experience solution for 32 years. We’ve been through hard times before, and we’ll make it through hard times again. If you need a ticketing and guest experience solution that is ready to be there for you, reach out: https://www.gatewayticketing.com/contact/.' },
    { image: "Title/JRA_4x3.jpg", url: 'www.jackrouse.com', email: 'cjames@jackrouse.com', name: 'Chloe Hausfeld', description: 'Named by the Wall Street Journal as “one of the world’s more prominent design firms,” JRA master plans, designs, and delivers exceptional visitor experiences for theme parks, museums, corporations, and leisure destinations. Since 1987, JRA’s work has engaged, informed, and entertained diverse audiences on six of the world’s seven continents.' },
    { image: "Title/TheShippingMonster_4x3.jpg", url: 'www.theshippingmonster.com', email: 'robert@theshippingmonster.com', name: 'Robert Aspinall', description: 'The Shipping Monster is a specialty global logistics company offering shipping solutions to the live events, attractions and entertainment industry. With over 200 offices worldwide we are everywhere you need us to be.' },
  ],
  presenting: [
    { image: "Presenting/Appetize_4x3.jpg", url: 'www.appetize.com', email: 'marketing@appetize.com', name: 'Katelyn Galante', description: 'Appetize powers food, beverage, and retail transactions for the world’s highest-volume businesses through its fully cloud-based Point of Sale, digital ordering, and enterprise management platform.' },
    { image: "Presenting/CAVU_4x3.jpg", url: 'www.cavudw.com', email: 'info@cavudw.com', name: 'Sarah Brack', description: 'CAVU Designwerks is an end-to-end ride system developer, taking theme park attractions to new heights; delivering services that yield exceptional experiences, ride performance, and ROI. ' },
    { image: "Presenting/Dreamcraft_4x3.jpg", url: 'www.dreamcraftattractions.com', email: 'info@dreamcraftattractions.com', name: 'Sarah Brack', description: 'DreamCraft is a real-time company, using VR, AR and projection mapping as their mediums to play outside the lines of real-time as we know it.  ' },
    { image: "Presenting/Fibrart_4x3.jpg", url: 'www.fibrart.com.mx', email: 'sales@fibrart.com.mx', name: 'Alejandro Mestas', description: 'Leading water attractions manufacturing since 1980, we manufacture and install water slides, kids play structures, kids areas, spraygrounds, wave pools and other water features.' },
    { image: "Presenting/Polin_4x3.jpg", url: 'www.polin.com.tr', email: 'sohret.pakis@polin.com.tr', name: 'Sohret Pakis', description: 'Polin is a leader in the design, R&D, production, and installation of waterparks and water-play attractions; with over 3,500 completed waterpark projects in 108 countries.' },
    { image: "Presenting/ProSlide_4x3.jpg", url: 'www.proslide.com', email: 'redwards@proslide.com', name: 'Robin Edwards', description: 'ProSlide is a leader in water ride design and manufacturing; powering the best parks in the industry and creating the world’s best water ride experiences' },
    { image: "Presenting/Qubica_4x3.jpg", url: 'www.qubicaamf.com', email: 'wsmith@qubicaamf.us', name: 'Wendy Smith', description: 'QubicaAMF is the world’s largest manufacturer of bowling and mini bowling products. Our mission is focused, yet simple, Making Bowling Amazing.  ' },
    { image: "Presenting/RocketRez_4x3.jpg", url: 'www.rocketrez.com', email: 'joe@rocket-rez.com', name: 'Joe Robinson', description: 'The All-in-One Platform for Tours & Attractions. Imagine all of your revenue streams in one easy to use API-based platform, always selling in real-time.  ' },
    { image: "Presenting/WTI_4x3.jpg", url: 'watertechnologyinc.com', email: 'jgerber@wtiworld.com', name: 'Jen Gerber', description: 'WTI is internationally recognized as a waterpark planning, design, and engineering firm with an award-winning portfolio, maintaining the widest range of experience in the waterpark industry. ' },
    { image: "Presenting/Whirley_4x3.jpg", url: 'www.whirleydrinkworks.com', email: 'aconarro@whirleydrinkworks.com', name: 'Al Conarro', description: "A global leader with 60 years' experience creating products and technology solutions that add value and maximize revenue in your food and beverage categories." },
  ],
  supporting: [
    { image: "Supporting/NeptuneBenson_4x3.jpg", url: 'www.evoqua.com/en/brands/neptune-benson/', email: 'nbsales@evoqua.com ', name: 'Scott Hyland' },
    { image: "Supporting/THG_4x3.jpg", url: 'thehettemagroup.com', email: 'kritch@thehettemagroup.com', name: 'Kelley Ritch' },
    { image: "Supporting/ICEE_4x3.jpg", url: 'www.icee.com', email: 'npeterson@icee.com', name: 'Natalie Peterson' },
    { image: "Supporting/JJSnackFoods-400x300.jpg", url: 'www.jjsnackfoodservice.com', email: 'NAbbattista@jjsnack.com', name: 'Norma Abbattista' },
    { image: "Supporting/JackOLantern_4x3.jpg", url: 'www.debbikatzproductions.com', email: 'debbi@debbikatzproductions.com', name: 'Debbi Katz' },
    { image: "Supporting/Kraftwerk_4x3.jpg", url: 'www.kraftwerk.at', email: 'marketing@kraftwerk.at', name: 'Julia Schabetsberger' },
    { image: "Supporting/McGowan_4x3_NEW.jpg", url: 'www.mcgowanallied.com', email: 'ashugart@mcgowanallied.com', name: 'Annie Shugart' },
    { image: "Supporting/NACE_4x3.jpg", url: 'www.nace.org', email: 'Kayla.Alvineda@nace.org', name: 'Kayla Alvineda' },
    { image: "Supporting/VAHLE_4x3.jpg", url: 'www.vahleinc.com', email: 'bwaters@vahleinc.com', name: 'Bill Waters' },
    { image: "Supporting/Vekoma_4x3.jpg", url: 'www.vekoma.com', email: 'charlotte.van.etten@vekoma.com', name: 'Charlotte van Etten' },
    { image: "Supporting/WeberGroup_4x3.jpg", url: 'www.webergroupinc.com', email: 'kathryngiles@webergroupinc.com', name: 'Kathryn Giles' },
  ],
}

export const eventsContent = [];

export const menu = []
export const individuals = {
  list: [],
  relations: [],
  commercial: [],
  about2018: [],
  about2019: [],
  objectives: [],
  statements: [],
  method: []
}
