import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    float: 'right'
  },
}));

const QuizFailure = ({passingScore, userScore, onRetryClick, goBackLink, isRetryLocked}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" gutterBottom>Try Again</Typography>
      <Typography>The passing score for this course is <b>{passingScore}%</b>.</Typography>
      <Typography>Your score is <b>{userScore}%</b>.</Typography>
      <br />
      <Typography>You may take this test as many times as you like</Typography>
      <br />
      <br />
      <div>
        <Button variant="contained" color="secondary" to={goBackLink} component={Link}>Go Back</Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={onRetryClick} disabled={isRetryLocked}>Try Again</Button>
      </div>
    </>
  );
};

QuizFailure.propTypes = {
  passingScore: PropTypes.number.isRequired,
  userScore: PropTypes.number.isRequired,
  onRetryClick: PropTypes.func.isRequired,
  goBackLink: PropTypes.string.isRequired,
  isRetryLocked: PropTypes.bool
};

export default QuizFailure;
