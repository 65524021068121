import { CometChat } from '@cometchat-pro/chat';

const appID = "19218fb2826d6ad";
const region = "us";
const apiKey = "745fdb95d14426f914b7b3b82cb647a91fdce52f";

export const CHAT_MODES = {
  LOCAL: 0,
  GLOBAL: 1,
}

export const createChatUser = (uid, name) => {
  return new Promise((resolve, reject) => {
    const chatUser = new CometChat.User(uid);
    chatUser.setName(name);
    CometChat.createUser(chatUser, apiKey).then(user => {
      console.log("[CHAT]", "User created", user);
      resolve(user);
    }).catch(error => {
      console.warn("[CHAT]", "User create error", error);
      reject(error);
    });
  })
}

export const chatLogin = uid => {
  return new Promise((resolve, reject) => {
    CometChat.login(uid, apiKey).then(user => {
      console.log("[CHAT]", "Login successfull", user);
      resolve(user);
    }).catch(error => {
      console.log("[CHAT]", "Login error", error);
      reject(error);
    });
  })
}

export const initListeners = () => {
  const msgListenerID = "msgListener_sound";
  CometChat.addMessageListener(msgListenerID, new CometChat.MessageListener({
    onTextMessageReceived: message => {
      console.log("[CHAT] Message received successfully:", message);
    },
    onMediaMessageReceived: message => {
      console.log("[CHAT] Message received successfully:", message);
    },
    onCustomMessageReceived: message => {
      console.log("[CHAT] Message received successfully:", message);
    },
    onMessageDeleted: message => {
      console.log("[CHAT] Deleted Message listener:", message);
    }
  }));

}

export const initChat = () => {
  return new Promise((resolve, reject) => {
    const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
    CometChat.init(appID, appSetting).then(() => {
      console.log("[CHAT]", "Initialization completed successfully");
      resolve();
    }).catch(error => {
      console.error("[CHAT]", "Initialization failed with error:", error);
      reject(error);
    });
  });
}