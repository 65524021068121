import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  videoWrapper: {
    paddingBottom: '56.25%',
    position: 'relative',
    display: 'block',
    height: 0,
    padding: 0,
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
}));

const Video = ({ src, style = {} }) => {
  const classes = useStyles();
  return (
    <div className={classes.videoWrapper} style={style} >
      <iframe src={src} frameBorder="0" height="auto" className={classes.video} title="Educational video" allowFullScreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
    </div>
  )
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
}

export default Video;
