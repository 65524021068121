import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplitude } from "react-amplitude-hooks";
import { inject } from "mobx-react";
import RestrictedAccessPage from "./RestrictedAccessPage";
import EventSignUpDialog from '../dialogs/EventSignUpDialog';
import { useHistory } from 'react-router-dom';
import uiConfig from '../../uiConfig';

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: ({ height, minHeight }) => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      '& iframe': {
        width: '100vw',
        border: 'none',
        overflowX: 'hidden',
        overflowY: "auto",
      }
    }),
    content: {
      whiteSpace: 'break-spaces'
    },
    header: {
      textAlign: 'center',
      marginTop: -30,
      '& img': {
        height: 90,
      }
    }
  })
});

// const formatName = ({ firstName, lastName }) => (firstName ? firstName + " " : "") + (lastName || "");

const EventLive = ({ event, user, store }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const { logEvent } = useAmplitude({ page: event.alias, email: user?.email });
  const history = useHistory();

  const handleDialogClose = (success, exhibitHallOnly) => {
    if (!success) {
      history.push("/");
      return;
    }
    store.fetchEvents();
    setShowDialog(false);
    if (exhibitHallOnly) {
      history.push(`/event/${event.alias}/exhibit-hall`);
    }
    logEvent("event registration completed");
  }

  useEffect(() => {
    if (event.hasAccess) return;
    if (!event.canRegister) return;
    setShowDialog(true);
  }, [event.hasAccess, event.canRegister]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;";
    return () => {
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    }
  }, []);

  const iframeQuery = user ? `?chatEmail=${user.email}&email=${user.email}&showSchedule=1` : "";
  if (!event.hasAccess) {
    if (event.canRegister)
      return (<EventSignUpDialog open={showDialog} handleClose={handleDialogClose} user={user} event={event} store={store} />);
    else
      return (<RestrictedAccessPage />);
  }
  let { alias } = event;
  return (
    <>
      <div className={classes.fullWidth}>
        <iframe src={`https://slidespiel.com/${alias}/live/embed${iframeQuery}`} allowFullScreen style={{ height: `calc(100vh - ${uiConfig.main.childrenMarginTop + 5}px)`, minHeight: 400 }} /* scrolling="no" */ />
      </div>
    </>
  );
}

export default inject("store")(EventLive);

