import React from 'react';
import Chat from '../Chat/Chat';
import { CHAT_MODES } from '../Chat/controller';

const ChatPage = () => {
  return (
    <div className="use-bootstrap1"><Chat type={CHAT_MODES.GLOBAL} /></div>
  );
};

export default ChatPage;