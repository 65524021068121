import React, { Fragment } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
// import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiPhoneNumber from 'material-ui-phone-number';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import jsonLogic from 'json-logic-js';
import Checkbox from '@material-ui/core/Checkbox';

const resetValidity = id => {
  const el = document.getElementById(id);
  if (!el) return;
  el.setCustomValidity("");
}

const PhoneInput = ({ id, label, mandatory, state, setState, disabled }) => {
  const handleChange = phone => {
    if (mandatory)
      resetValidity(id);
    setState({ [id]: phone })
  };
  return (
    <MuiPhoneNumber
      defaultCountry={'us'}
      onChange={handleChange}
      fullWidth
      label={label}
      required={mandatory}
      value={state[id]}
      id={id}
      disabled={disabled}
    />
  );
}

const TextInput = ({ id, label, regex, maxLength, mandatory, state, setState, autoComplete, disabled, rows }) => {
  const handleChange = ({ target: { value } }) => {
    if (maxLength) {
      if (value.length > maxLength) return;
    }
    if (regex) {
      const re = new RegExp(regex);
      if (!re.test(value)) return;
    }
    setState({ [id]: value });
  }
  return (
    <TextField
      id={id}
      label={label}
      fullWidth
      value={state[id]}
      onChange={handleChange}
      required={mandatory}
      autoComplete={autoComplete}
      disabled={disabled}
      multiline={rows > 1}
      rows={rows}
      variant={rows > 1 ? "outlined" : "standard"}
    />
  );
}

const RadioInput = ({ id, label, row, mandatory, state, setState, options, disabled }) => {
  const handleChange = ({ target: { value } }) => {
    if (mandatory)
      resetValidity(id + '0');
    setState({ [id]: value });
  }
  return (
    <FormControl component="fieldset" required={mandatory} disabled={disabled}>
      <FormLabel component="legend">
        {label}
      </FormLabel>
      <RadioGroup value={state[id]} onChange={handleChange} row={row}>
        {options.map((opt, index) =>
          <FormControlLabel key={index} value={opt.value} control={<Radio id={id + index} />} label={opt.label} />
        )}
      </RadioGroup>
    </FormControl>
  );
}

const LabelLink = ({ link }) => {
  const regex = /https?:\/\/[www.]?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9@:%_+.~#?&//=]*/g;
  if (regex.test(link))
    return <Link href={link} target="_blank" rel="noreferrer noopener">{link}</Link>;
  return <Link href={"mailto:" + link}>{link}</Link>
}

const CheckboxInput = ({ id, label, mandatory, state, setState, disabled }) => {
  const handleChange = ({ target: { checked } }) => {
    if (mandatory)
      resetValidity(id);
    setState({ [id]: checked });
  }

  const getLabel = () => {
    // const regex = /https?:\/\/[www.]?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9@:%_+.~#?&//=]*/mg;
    const regex = /(https?:\/\/[www.]?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9@:%_+.~#?&//=]*)|(\S+@\S+\.\w+)/g;
    const links = label.match(regex);
    if (!links)
      return label;
    let result = label;
    for (let link of links) {
      result = result.replace(link, "<link>");
    }
    const parts = result.split("<link>");
    return (
      <>
        {parts.map((part, index) =>
          <Fragment key={index}>
            {part} {index < parts.length - 1 && <LabelLink link={links[index]} />}
          </Fragment>
        )}
      </>
    );
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          checked={state[id]}
          onChange={handleChange}
          color="primary"
        />
      }
      label={getLabel()}
      disabled={disabled}
    />
  )
}

const SelectInput = ({ id, label, mandatory, state, setState, options, errors, setErrors, disabled }) => {
  const handleChange = ({ target: { value } }) => {
    setState({ [id]: value });
    setErrors({ [id]: null });
  }
  return (
    <FormControl required={mandatory} fullWidth disabled={disabled}>
      <InputLabel id={id + "-label"}>{label}</InputLabel>
      <Select
        labelId={id + "-label"}
        value={state[id]}
        onChange={handleChange}
        fullWidth
      >
        {!mandatory && <MenuItem value="">Empty</MenuItem>}
        {options.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)}
      </Select>
      {errors[id] && <FormHelperText error>{errors[id]}</FormHelperText>}
    </FormControl>
  );
}

const Input = ({ xs, sm, md, type, ...rest }) => (
  <Grid item xs={Number.parseInt(xs)} sm={Number.parseInt(sm)} md={Number.parseInt(md)}>
    {type === "phone" && <PhoneInput {...rest} />}
    {type === "text" && <TextInput {...rest} />}
    {type === "radio" && <RadioInput {...rest} />}
    {type === "checkbox" && <CheckboxInput {...rest} />}
    {type === "select" && <SelectInput {...rest} />}
  </Grid>
);

const CustomFormFields = ({ metaData, state, errors, setState, setErrors }) => {
  return (
    <>
      {metaData?.fields.map(field => {
        if (field.condition && !jsonLogic.apply(field.condition, state))
          return null;
        return <Input key={field.id} state={state} errors={errors} setState={setState} setErrors={setErrors} {...field} />
      })}
    </>
  );
}

export default CustomFormFields;