import React from "react";
import PropTypes from "prop-types";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import config from '../../config';

const QuizMenu = ({ step }) => {
  const items = [
    null,
    "Review course details",
    config.questionsBeforeRecording ? "Answer questions" : -1,
    "View recording",
    "Answer questions",
    "Get your CME certificate", // Try Again
    null, // Success
    null, // Get Certificate
  ];

  if (step === 6 || step === 7)
    step = 5;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stepper orientation={isSm ? "vertical" : "horizontal"}>
      {items.filter(Boolean).map((item, index) => {
        //console.log(step - 1, index);
        if (item === -1) return null;
        return (
          <Step key={index} active={index === step - 1} completed={index < step - 1}>
            <StepLabel>{item}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

QuizMenu.propTypes = {
  step: PropTypes.number.isRequired
};

export default QuizMenu;
