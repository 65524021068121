import React, { useCallback } from "react";
import { Route } from "react-router-dom";
import { inject } from "mobx-react";
import RestrictedAccessPage from "./pages/RestrictedAccessPage";
//import config from '../config';

const SecuredRoute = ({ isLoggedIn, secured, component, path, authRedirect, children, ...rest }) => {
  // if (path.includes("exhibit-hall"))
    // console.log("security", secured, isLoggedIn, path, authRedirect);
  const restrictedComponent = useCallback(() => (<RestrictedAccessPage authRedirect={authRedirect} />), [authRedirect]);
  if (children) {
    return (
      <Route path={path} {...rest} >
        {((secured && isLoggedIn) || !secured) ? children : <RestrictedAccessPage authRedirect={authRedirect} />}
      </Route>
    );
  }
  let newComponent = component;
  if (secured)
    newComponent = isLoggedIn ? component : restrictedComponent;
  return (<Route component={newComponent} path={path} {...rest} />);
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn
}))(SecuredRoute);
