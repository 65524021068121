import React from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import config from '../../config';
import Video from './Video';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    float: 'right'
  },
  videoContainer: {
    margin: '0 10%',
  },
  info: {
    display: 'inline-block',
  }
}));

const QuizVideoEmbed = ({ recording, onNextClick, isNextLocked, onRetryClick, attemptsLeft }) => {
  const getSrc = (alias) => {
    return `https://slidespiel.com/view/${alias}`;
  };

  const getSpeakersString = (speakers) => {
    return speakers.map((speaker) => `${speaker.firstName} ${speaker.lastName}`).join(", ");
  };

  const getTracksString = (tracks) => {
    return tracks.map((track) => track.title).join(", ");
  };

  const classes = useStyles();
  const nextButton = (<Button variant="contained" color="primary" className={classes.button} onClick={onNextClick} disabled={isNextLocked}>Start {config.questionsBeforeRecording ? "Post-test" : "Test"}</Button>);
  return (
    <>
      <div>
        <div className={classes.info}>
          <Typography variant="subtitle1">Speakers: {getSpeakersString(recording.speakers)}</Typography>
          <Typography variant="subtitle1">Track: {getTracksString(recording.tracks)}</Typography>
          <Typography variant="subtitle1">Attempts Left: {attemptsLeft}</Typography>
        </div>
        {nextButton}
      </div>
      <br />
      <div className={classes.videoContainer}>
        <Video src={getSrc(recording.alias)} />
      </div>
      <br />
      <br />
      <div>
        <Button variant="contained" color="secondary" onClick={onRetryClick} disabled={isNextLocked}>Back</Button>
        {nextButton}
      </div>
    </>
  );
};

QuizVideoEmbed.propTypes = {
  recording: PropTypes.object.isRequired,
  onNextClick: PropTypes.func.isRequired,
  isNextLocked: PropTypes.bool
};

export default QuizVideoEmbed;
