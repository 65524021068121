import React from 'react';

import { makeStyles, fade } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
//import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles(theme => ({
  headerTile: {
    height: 'auto !important'
  },
  tile: {
    cursor: 'pointer',
    "& img": {
      // padding: '10%',
      // width: '80%',
      transform: 'scale(0.8) translateY(-60%)',
      transition: 'transform .5s ease',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1) translateY(-50%)',
      }
    }
  },
  bar: {
    backgroundColor: fade(theme.palette.primary.main, .7),
  },
  barTitle: {
    fontSize: '0.92rem'
  }
}));

const TracksGridList = ({ tracks, handleClick, cols = 4 }) => {
  const classes = useStyles();

  return (
    <GridList cellHeight={200} cols={cols}>
      {/*<GridListTile key="Subheader" cols={cols} className={classes.headerTile}>
        <ListSubheader component="div">Tracks</ListSubheader>
      </GridListTile>*/}
      {tracks.map((track, index) => (
        <GridListTile key={track.id} onClick={handleClick(track.id)} className={classes.tile}>  
          <img src={track.imageUrl} alt={track.title} />
          <GridListTileBar
            title={track.title}
            className={classes.bar}
            classes={{ title: classes.barTitle }}
            //subtitle={<span>by: {track.author}</span>}
          />
        </GridListTile>
      ))}
    </GridList> 
  );
}

export default TracksGridList;
