import moment from 'moment';

export const formatDateInterval = (startDateU, endDateU) => {
  const startDate = moment(startDateU);
  const endDate = moment(endDateU);
  if (!endDateU || endDateU === startDateU)
    return startDate.format("LL");
  if (startDate.month() === endDate.month())
    return `${startDate.format("MMMM D")} – ${endDate.format("D, YYYY")}`;
  return moment(startDate).format("MMMM D") + " – " + moment(endDate).format("LL");
}