import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography"
import { inject } from "mobx-react";

const RestrictedAccessPage = ({ isLoggedIn, isLoggingIn, authRedirect = "/login" }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    // console.log("effect", isLoggedIn, isLoggingIn);
    if (isLoggedIn) return;
    if (isLoggingIn) return;
    if (!pathname.includes("/login") && !pathname.includes("/signup"))
      history.push(pathname + authRedirect);
  }, [isLoggedIn, isLoggingIn])

  return (
    <div className="container protected-page">
      <br />
      <br />
      <Typography variant="h3" gutterBottom>Forbidden</Typography>
      <Typography variant="subtitle1">{"You don't have permissions to access this page"}</Typography>
    </div>
  );
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn,
  isLoggingIn: store.isLoggingIn,
}))(RestrictedAccessPage);
