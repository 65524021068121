import { useParams } from 'react-router-dom';
import config from '../../config';
import uiConfig from '../../uiConfig';
import { portals } from '../../data';

const usePortal = passedYear => {
  let { year = passedYear || config.defaultYear } = useParams();
  //console.log(useParams());
  year = parseInt(year);
  const portalName = config.portalName + "-" + year;
  const portalId = config.portals.find(x => x.year === year).id;
  const data = portals.find(x => x.year === year);
  const uiSettings = uiConfig.portals.find(x => x.year === year);
  //console.log(portalName);
  return { portalId, portalName, year, data, uiSettings, urlPrefix: year !== config.defaultYear ? "/" + year : "" };
};

export default usePortal;