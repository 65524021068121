import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import uiConfig from '../../uiConfig';

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: ({ height, minHeight }) => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      // height,
      // minHeight,
      // backgroundSize: 'cover !important',
      // display: 'flex',
      // justifyContent: 'flex-end',
      // alignItems: 'center',
      '& iframe': {
        width: '100vw',
        border: 'none',
        overflowX: 'hidden',
        overflowY: 'auto',
      }
    }),
    content: {
      whiteSpace: 'break-spaces'
    },
    header: {
      textAlign: 'center',
      marginTop: -30,
      '& img': {
        height: 90,
      }
    }
  })
});

const formatName = ({ firstName, lastName }) => (firstName ? firstName + " " : "") + (lastName || "");

const NetworkingRoom = ({ room, event, user, store }) => {
  const classes = useStyles();

  useEffect(() => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;";
    return () => {
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    }
  }, []);

  if (!user) return null;
  const iframeQuery = `?userName=${formatName(user)}&email=${user.email}`;
  return (
    <div className={classes.fullWidth}>
      <iframe src={`https://slidespiel.com/${event.alias}/networking-room/${room.id}${iframeQuery}`} allowFullScreen allow="camera; microphone" style={{ height: `calc(100vh - ${uiConfig.main.childrenMarginTop + 5}px)` }} /* scrolling="no" */ />
    </div>
  );
}

export default NetworkingRoom;