import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment-timezone';
import draftToHtml from 'draftjs-to-html';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    "& td": {
      // fontSize: '1rem',
    }
  },
  root: {
    width: '100%',
    maxWidth: '50vw',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'left',
    zIndex: 20000,
    padding: theme.spacing(0.25, 1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: '90vw',
    }
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
    "& h4": {
      marginRight: 10,
    }
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  popover: {
    // pointerEvents: 'none',
    zIndex: 88,
  },
  speaker: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    // width: theme.spacing(4),
    // height: theme.spacing(4),
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
  },
  date: {
    overflowX: 'visible',
    whiteSpace: 'nowrap',
    minWidth: 80,
    maxWidth: 80,
  },
  room: {
    minWidth: 85,
  },
  dataRow: {
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    }
  }
}));

const formatName = ({ firstName, lastName }) => (firstName ? firstName + " " : "") + (lastName || "");

const SchedulePopover = ({ popoverData, anchorEl, handlePopoverClose }) => {
  const [description, setDescription] = useState("");
  const [descriptionType, setDescriptionType] = useState("plain");
  const classes = useStyles();

  useEffect(() => {
    if (!popoverData.description) {
      setDescription("");
      return;
    }
    let description;
    try {
      description = draftToHtml(JSON.parse(popoverData.description));
      setDescriptionType("draft");
    }
    catch {
      description = popoverData.description;
      setDescriptionType("plain");
    }
    setDescription(description);
  }, [popoverData.description]);
  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handlePopoverClose}>
      <Popper
        id={popoverData.id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        placement="bottom"
        className={classes.popover}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={500}>
            <Paper className={classes.root} elevation={2}>
              <div className={classes.section1}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h4">{popoverData.title}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom={!!popoverData.description} variant="h6">{moment.utc(popoverData.startTime).local().format("LLL")}</Typography>
                  </Grid>
                </Grid>
                {(description && descriptionType === "plain") && <Typography color="textSecondary" variant="body2">{popoverData.description}</Typography>}
                {(description && descriptionType === "draft") && <Typography component="div" variant="body2" dangerouslySetInnerHTML={{ __html: description }} />}
              </div>
              {popoverData.speakers?.length > 0 &&
                <>
                  <Divider variant="middle" />
                  <div className={classes.section2}>
                    {/* <Typography gutterBottom variant="body1">
                          Select type
                        </Typography> */}
                    <Grid container>
                      {popoverData.speakers?.map((speaker, index) => <Speaker gridSize={6} size={7} speaker={speaker} key={index} />)}
                    </Grid>
                    <div>
                    </div>
                  </div>
                </>
              }
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
}

const Speaker = ({ speaker, size = 5, gridSize = 12 }) => {
  const classes = useStyles();
  const theme = useTheme();
  const words = formatName(speaker).split(' ');
  const avatarText = words[0].charAt(0)// + words.pop().charAt(0);
  return (
    <Grid item xs={gridSize ? 12 : undefined} sm={gridSize || undefined} className={classes.speaker}>
      <Avatar src={speaker.avatarUrl} className={classes.avatar} style={{ width: theme.spacing(size), height: theme.spacing(size) }}>{avatarText}</Avatar>
      <Typography>{formatName(speaker)}</Typography>
    </Grid>
  );
}

export default function Schedule({ data, showHeader = false }) {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [popover, setPopover] = useState({});

  const handlePopoverClose = () => setPopover(x => ({ ...x, anchorEl: null }));
  const handlePopoverOpen = data => event => setPopover({ anchorEl: event.currentTarget, data });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const rooms = useMemo(() => {
    data.sort((a, b) => a.roomOrder - b.roomOrder).sort((a, b) => moment(a.startTime) - moment(b.startTime));
    let rooms = [...new Set(data.map(x => x.roomName))].map(x => data.find(c => c.roomName === x)).map(x => ({ name: x?.roomName, order: x?.roomOrder || 0 }));
    rooms = [{ name: "All Rooms", order: -1 }, ...rooms].sort((a, b) => a.order - b.order);
    return rooms;
  }, [data]);

  const { anchorEl } = popover;
  const popoverData = popover.data || {};
  let subheading = "";
  const timezone = moment.tz(moment.tz.guess());
  return (
    <>
      <SchedulePopover handlePopoverClose={handlePopoverClose} popoverData={popoverData} anchorEl={anchorEl} />
      <Typography variant="caption" align="center" component="div">Times are listed in your time zone – {timezone.format('zz')} (UTC{timezone.format('Z')})</Typography>
      {rooms.length > 2 &&
        <Tabs
          value={tab}
          indicatorColor="secondary"
          textColor="primary"
          onChange={(x, value) => setTab(value)}
          centered={!fullScreen}
          variant={fullScreen ? "scrollable" : "standard"}
          scrollButtons="auto"
        >
          {rooms.map((x, i) => <Tab label={x.name} key={i} />)}
        </Tabs>
      }
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          {showHeader &&
            <TableHead>
              <TableRow>
                <TableCell>Date & Time</TableCell>
                {tab === 0 && <TableCell>Room</TableCell>}
                <TableCell>Session</TableCell>
                {/* <TableCell>Speakers</TableCell> */}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {data.filter(x => tab === 0 || x.roomName === rooms[tab].name).map(row => {
              const dateTime = moment.utc(row.startTime).local();
              const date = dateTime.format("LL");
              const time = dateTime.format("LT")
              const addSubheading = subheading !== date;
              if (addSubheading)
                subheading = date;
              return (
                <Fragment key={row.id}>
                  {addSubheading &&
                    <TableRow>
                      <TableCell className={classes.date}><strong>{date}</strong></TableCell>
                      <TableCell></TableCell>
                      {tab === 0 && <TableCell></TableCell>}
                    </TableRow>
                  }
                  <TableRow /* onMouseLeave={handlePopoverClose} */ onClick={handlePopoverOpen(row)} className={classes.dataRow}>
                    <TableCell className={classes.date}>{time}</TableCell>
                    {(tab === 0 && rooms.length > 2) && <TableCell className={classes.room}>{row.roomName}</TableCell>}
                    <TableCell>
                      <Typography gutterBottom variant="h6">{row.title}</Typography>
                      <Grid container spacing={1}>
                        {row.speakers.map((speaker, index) => <Speaker speaker={speaker} key={index} gridSize={0} />)}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}