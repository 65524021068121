import React, { useState, useReducer, useEffect, useRef } from 'react';
// import { inject } from "mobx-react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from "../../api";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import Checkbox from '@material-ui/core/Checkbox';
import MuiInput from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { useFeedback } from '../feedback/Service';
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';
// import { useHistory } from 'react-router-dom';
import jsonLogic from 'json-logic-js';
import usePortal from '../hooks/portal';
import CloseButton from "../CloseButton";
import OkIcon from '@material-ui/icons/Done';
import CustomFormFields from '../CustomFormFields';
import { useHistory, useParams } from 'react-router-dom';
import { inject } from "mobx-react";
import uiConfig from '../../uiConfig';
import PrivacyPolicyCheckbox from '../PrivacyPolicyCheckbox';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(2, 0, 2),
    minWidth: 120,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
    marginTop: 20,
  },
  close: {
    padding: theme.spacing(0.5),
  },
  support: {
    //marginBottom: 0,
    minWidth: 210,
    marginLeft: 15,
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  },
  title: {
    paddingBottom: 0,
  },
}));

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const reducer = (state, newState) => ({
  ...state,
  ...newState
});

const setValidity = (id, text) => {
  const el = document.getElementById(id);
  el.setCustomValidity(text);
  el.reportValidity();
}

const getInitialState = (signUpFormMetaData, user) => {
  let initialState = signUpFormMetaData ? Object.fromEntries(signUpFormMetaData.fields.map(x => [x.id, x.type === "checkbox" ? x.defaultChecked : x.defaultValue])) : {};
  initialState.promoCode = "";
  initialState.exhibitHallOnly = false;
  if (user) {
    const { email, firstName, lastName } = user;
    return initialState = { ...initialState, email, firstName, lastName };
  } else {
    return initialState = { ...initialState, email: '', firstName: '', lastName: '' };
  }
}

const PromoCode = ({ setPrice, handleChange, value, eventId }) => {
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [isPriceUpdating, setIsPriceUpdating] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const updatePrice = async () => {
      setIsPriceUpdating(true);
      const { price, isReasonApplied } = await api.getEventPrice(eventId, value ? "PromoCode" : null, value);
      // console.log("got price", price, isReasonApplied);
      setPrice(price);
      setIsPromoCodeApplied(isReasonApplied)
      setIsPriceUpdating(false);
    }
    if (!timeoutRef.current) {
      timeoutRef.current = -1;
      updatePrice();
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => updatePrice(), 500);
    return (() => {
      clearTimeout(timeoutRef.current);
    });
  }, [value, eventId]);

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="promoCode">Promo Code</InputLabel>
      <MuiInput
        id="promoCode"
        label="Promo Code"
        name="promoCode"
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            {isPriceUpdating ? <CircularProgress size={25} /> : (isPromoCodeApplied && <OkIcon color="primary" />)}
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

const getPricingData = (reasons, state) => {
  let pricing = {};
  for (let reason of reasons) {
    pricing[reason] = state[reason];
  }
  return JSON.stringify(pricing);
}

const BuyProductDialog = ({ user, stripe, store, product }) => {
  const signUpFormMetaData = JSON.parse(product.signUpFormMetaData.replaceAll("'", '"'));
  // signUpFormMetaData.fields[1].condition = { "==": [{ "var": "text1" }, "apple"] };
  const initialState = getInitialState(signUpFormMetaData, user);
  const initialErrorState = signUpFormMetaData ? Object.fromEntries(signUpFormMetaData.fields.map(x => [x.id, null])) : {};
  const [state, setState] = useReducer(reducer, initialState);
  const [errors, setErrors] = useReducer(reducer, initialErrorState);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const feedback = useFeedback();
  // const history = useHistory();
  const { portalName, uiSettings: { signInRedirect } } = usePortal();
  const timeoutRef = useRef(null);
  const prevState = usePrevious(state);
  const history = useHistory();

  const handleClose = () => history.push("/");

  const handleChange = ({ target: { name, value, type, checked } }) => setState({ [name]: type === "checkbox" ? checked : value });

  const handleSubmit = async e => {
    e.preventDefault();
    for (let field of signUpFormMetaData.fields) {
      if (field.condition && !jsonLogic.apply(field.condition, state))
        continue;
      if (field.type === "phone" && field.mandatory) {
        if (state[field.id].length < 8) {
          console.warn("validation error", field.id);
          setValidity(field.id, field.label + " required");
          return;
        }
      }
      if (field.type === "radio" && field.mandatory) {
        if (!state[field.id]) {
          console.warn("validation error", field.id);
          setValidity(field.id + "0", "Please choose an option");
          return;
        }
      }
      if (field.type === "checkbox" && field.mandatory) {
        if (!state[field.id]) {
          console.warn("validation error", field.id);
          setValidity(field.id, "You must check to proceed");
          return;
        }
      }
      if (field.type === "select" && field.mandatory) {
        if (!state[field.id]) {
          console.warn("validation error", field.id);
          setErrors({ [field.id]: "Please choose an option" })
          // setValidity(field.id, "Please choose an option");
          return;
        }
      }
    }
    setLoading(true);
    let tokenId;
    if (price) {
      const { token, error } = await stripe.createToken({ type: 'card' });
      if (error) {
        console.warn("Payment error", error);
        feedback.snackbar({ text: error.message, type: "error" });
        setLoading(false);
        return;
      }
      if (!token?.id) {
        console.warn("Empty token", token);
        feedback.snackbar({ text: "Unknown payment error", type: "error" });
        setLoading(false);
        return;
      }
      tokenId = token.id;
    }

    const { firstName, lastName, email, phone/* , exhibitHallOnly  */} = state;
    const formData = {
      firstName,
      lastName,
      email,
      phone,
      token: tokenId,
      promoCode: state.promoCode,
      productId: product.id,
      // portalId,
      signUpFormData: JSON.stringify(state),
      // exhibitHallOnly,
      pricing: getPricingData(product.pricingReasons, state),
    };
    console.log(formData);
    try {
      const { body: data } = await api.buyProduct(formData);
      console.log(data);
      localStorage.setItem(`${portalName}-access_token`, data.accessToken);
      const { body: user } = await api.getUser("me", data.accessToken);
      feedback.snackbar({ text: "Registration completed!", type: "success" });
      store.setUser(user);
    }
    catch (error) {
      console.warn("Buy product error", error.response);
      feedback.snackbar({ text: error.response?.body?.message || "Unknown error", type: "error" });
      setLoading(false);
      return;
    }

    // setLoading(false);
    await store.fetchEvents();
    // handleClose();
    history.push(signInRedirect);
  }

  useEffect(() => {
    if (state.exhibitHallOnly) {
      setPrice(0);
      return;
    }
    // const hasDomainReason = event.pricingReasons.includes("Domain");
    // const hasMemberReason = event.pricingReasons.includes("Member");
    // if (!hasDomainReason && !hasMemberReason)
    //   return;
    // const reason = hasDomainReason ? "Domain" : "Member";
    if (product.pricingReasons.length === 0)
      return;
    if (prevState) {
      let hasChanges = false;
      for (let reason of product.pricingReasons) {
        if (state[reason] !== prevState[reason])
          hasChanges = true;
      }
      if (!hasChanges)
        return;
    }

    const updatePrice = async () => {
      setLoading(true);
      const data = {};
      for (let reason of product.pricingReasons) {
        data[reason] = state[reason];
      }
      const { price, isReasonApplied } = await api.getProductPrice(product.id, data);
      console.log("got price", price, isReasonApplied);
      setPrice(price);
      setLoading(false);
    }
    if (!timeoutRef.current) {
      timeoutRef.current = -1;
      updatePrice();
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => updatePrice(), 500);
    return (() => {
      clearTimeout(timeoutRef.current);
    });
  }, [product, state, prevState]);

  const size = signUpFormMetaData?.size || "sm";

  console.log(product);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog open aria-labelledby="buy-dialog-title" fullWidth maxWidth={size} onClose={handleClose} fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={handleClose} />}
      <DialogTitle id="buy-dialog-title" className={classes.title}>{product.title} Checkout</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id="buy-sponsorship-form" className={classes.form}>
          <Grid container spacing={2}>
            {!user &&
              <>
                <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                  <TextField
                    autoFocus
                    label="First Name"
                    fullWidth
                    name="firstName"
                    value={state.firstName}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                  <TextField
                    label="Last Name"
                    fullWidth
                    name="lastName"
                    value={state.lastName}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                  <TextField
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    value={state.email}
                    onChange={handleChange}
                    helperText={uiConfig.emailHelperText || undefined}
                    required
                  />
                </Grid>
              </>
            }
            <CustomFormFields metaData={signUpFormMetaData} state={state} errors={errors} setState={setState} setErrors={setErrors} />
            {/* {!product.hasExhibitHallAccess &&
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.exhibitHallOnly}
                      onChange={handleChange}
                      name="exhibitHallOnly"
                      color="primary"
                    />
                  }
                  label="Free Sign Up for Exhibit Hall Only"
                />
              </Grid>
            } */}
            {(product.pricingReasons?.includes("PromoCode") && !state.exhibitHallOnly) &&
              <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 4}>
                <PromoCode setPrice={setPrice} handleChange={handleChange} value={state.promoCode} eventId={product.id} />
              </Grid>
            }
            {uiConfig.acceptPrivacyPolicyText &&
              <Grid item xs={12} >
                <PrivacyPolicyCheckbox checked={privacyAccepted} handleChange={setPrivacyAccepted} />
              </Grid>
            }
          </Grid>
          {price ?
            <>
              <br />
              <CardElement style={{ base: { fontSize: 14 } }} className={classes.stripe} hidePostalCode />
            </> : null
          }
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading || (uiConfig.acceptPrivacyPolicyText && !privacyAccepted)}
            >
              {price ? "Pay $" + price : "Submit"}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </form>
        {/* <Typography variant="caption">If you have questions about your membership status or registration, contact NCSEA at <Link href="mailto:ncsea@ncsea.com">ncsea@ncsea.com</Link>, or call 312-649-4600, ext. 200.</Typography> */}
      </DialogContent>
      {/* <DialogActions>
      <Typography className={classes.support} variant="body2" gutterBottom>Support: <Link href={`mailto:${uiConfig.supportEmail}`}>{uiConfig.supportEmail}</Link></Typography>
      <div style={{ width: '100%' }} />
    </DialogActions> */}
    </Dialog>
  );
}

const DialogWrapper = ({ products, ...rest }) => {
  const { productId } = useParams();
  const product = products.find(x => x.id === productId);
  if (!product) return null;
  return <BuyProductDialog product={product} {...rest} />
}

const StoreInjectedDialog = inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, events: store.events, products: store.products, user: store.currentUser, store
}))(DialogWrapper);

const StripeInjectedDialog = injectStripe(StoreInjectedDialog);

const StripeDialog = props => (
  <Elements>
    <StripeInjectedDialog {...props} />
  </Elements>
)

export default StripeDialog
