import React, { createContext, useContext, useState, useRef } from 'react';
import Dialog from './Dialog';
import Snackbar from './Snackbar';

const Context = createContext(Promise.reject);

export const useFeedback = () => useContext(Context);

export const FeedbackProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null);
  const [snackbarState, setSnackbarState] = useState(null);
  const awaitingPromiseRef = useRef();
  const confirm = (options, isAlert = false) => {
    setConfirmationState({ ...options, isAlert });
    if (isAlert) return;
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };
  const snackbar = (options) => setSnackbarState(options);
  const handleClose = () => {
    if (!awaitingPromiseRef.current)
      return;
    if (!confirmationState.isAlert && confirmationState.catchOnCancel)
      awaitingPromiseRef.current.reject();
    else
      awaitingPromiseRef.current.resolve(false);
    setConfirmationState(null);
  };
  const handleSnackbarClose = () => setSnackbarState(null);
  const handleSubmit = (value) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(value);
    }
    setConfirmationState(null);
  };
  return (
    <>
      <Context.Provider
        value={{ snackbar, confirm, alert: options => confirm(options, true), form: options => confirm({ ...options, maxWidth: 'sm', fullWidth: true }) }}
      >
        {children}
      </Context.Provider>
      <Dialog
        open={!!confirmationState}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        {...confirmationState}
      />
      <Snackbar
        open={!!snackbarState}
        handleClose={handleSnackbarClose}
        {...snackbarState}
      />
    </>
  );
};
