import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Grid from "@material-ui/core/Grid";
import Sponsors from "../Sponsors";
import uiConfig from '../../uiConfig';
import { inject } from "mobx-react";

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    "& h4": {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    "& hr": {
      borderColor: theme.palette.secondary.main,
      width: '100%',
      marginBottom: 30,
    }
  },
  thanks: {
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    height: 80,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    paddingTop: theme.spacing(1.5),
    "& > h3": {
      fontWeight: 600,
    }
  }
}));

const SponsorsPage = ({ portal }) => {
  const classes = useStyles();
  return (
    <>
      {/* <div className={classes.thanks}>
        <Typography variant="h3">Thank you to our sponsors!</Typography>
      </div> */}
      <div className={classes.container}>
        <br />
        <br />
        {portal.sponsorship.map(level =>
          <React.Fragment key={level.id}>
            <Typography variant="h4" color="primary" gutterBottom>{level.title || "Sponsors"}</Typography>
            <hr />
            <Sponsors data={level.sponsors} showCards={uiConfig.sponsorsPageCards} horizontalCards={uiConfig.sponsorsCardsHorizontal} />
            <br />
          </React.Fragment>
        )}
      </div>
    </>
  );
}

export default inject(({ store }, props) => ({
  portal: store.portal,
}))(SponsorsPage);