/* eslint-disable */
const config = {
  apiHost: "https://slidespielportalsapitest.azurewebsites.net",
  spmApiHost: "https://slidespielspeakersapi.azurewebsites.net",
  portalPrice: 40,
  portalPriceForMembers: 30,
  stripeKey: "pk_test_51INjzqKvZUsxjwRsSSORMOjijaXiR1gFqbQmteDPWAWuOBFfazkaYY1LbRLm6jwMYlszC1ScgspMIJBKu3DUYoht00PrZPgRQo",
  url: "",
  env: "development",
  portalName: "iaapa",
  title: "IAAPA Virtual Conference",
  hasCme: false,
  questionsBeforeRecording: false,
  portals: [
    { year: 2020, id: "e168e660-ac2b-4294-b3c6-3ff78bdac2d2" },
  ],
  defaultYear: 2020,
  // amplitudeKey: "5a691f5d28c551997691879d728c1da7",
  version: "0.1.16",
};

if (process.env.NODE_ENV === "production") {
  Object.assign(config, {
    "apiHost": "https://slidespielportalsapi.azurewebsites.net",
    "stripeKey": "pk_live_51INjzqKvZUsxjwRsSGrerxFJghrSwP7Z1SRYPdAQcpLW6hvdqWvkFKyMKDiT8U3TQPAY65T0bDnnYKUQSk5PXHfU00iG0HorZm",
    "env": "production",
    "sentryDsn": "https://66ebec47e0014dccb50af5e51f5348eb@o361175.ingest.sentry.io/5264442",
  });
}


export default config;
