import React from 'react';
import amplitude from "amplitude-js";
import { AmplitudeProvider } from "react-amplitude-hooks";
import config from '../config';
import { inject } from "mobx-react";

const AmplitudeWrapper = ({ user, children }) => (
  <AmplitudeProvider
    amplitudeInstance={amplitude.getInstance()}
    apiKey={config.amplitudeKey}
    userId={user?.id}
  >
    {children}
  </AmplitudeProvider>
)

export default inject(({ store }, props) => ({
  user: store.currentUser,
}))(AmplitudeWrapper);