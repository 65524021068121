import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  selected: {
    backgroundColor: fade(theme.palette.secondary.main, .3) + ' !important',
  },
  subheader: {
    position: 'static',
  }
}));

const Filter = ({ title, items, selected, handleItemClick, defaultItem = null }) => {
  const classes = useStyles();
  return (
    <List
      dense
      component="nav"
      aria-labelledby={title + "-list-subheader"}
      subheader={
        <ListSubheader component="div" id={title + "-list-subheader"} className={classes.subheader}>
          {title}
        </ListSubheader>
      }
    >
      {defaultItem && 
        <ListItem button selected={selected === 0} onClick={handleItemClick(defaultItem.id)} classes={{ selected: classes.selected }}>
          <ListItemText primary={defaultItem.title} />
        </ListItem>
      }
      {items.map(item => (
        <ListItem button key={item.id} selected={selected === item.id} onClick={handleItemClick(item.id)} classes={{ selected: classes.selected }}>
          <ListItemText primary={item.title} />
        </ListItem>
      ))}
    </List>
  );
}

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  handleItemClick: PropTypes.func.isRequired,
  defaultItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired
  })
};

export default Filter;  
